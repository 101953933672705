import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Quote from 'components/quote/quote.component';
import Hero, { HeroWithBackgroundImage } from 'components/hero/hero.component';
import PageLayout from 'components/layouts/page/page.layout';
import HowItWorks from 'components/how-it-works/how-it-works.component';

import PageSection from 'ui-kit/page-section/page-section';
import FrequentlyAskedQuestions from 'components/frequently-asked-questions/frequently-asked-questions.component';
import FeatureHighlight from 'components/feature-highlight/feature-highlight.component';
import IconGrid from 'components/icon-grid/IconGrid';
import GridIcon from 'components/icon-grid/GridIcon/GridIcon';
import QuoteIcon from 'ui-kit/icons/quote/quote-icon';

const Home = () => {
    const imageData = useStaticQuery(graphql`
        query {
            pill: file(relativePath: { eq: "assets/images/hero-pill-image-sample.png" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
                }
            }
            feather: file(relativePath: { eq: "assets/images/feather-sample.png" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
                }
            }
            howItWorksDelivery: file(relativePath: { eq: "assets/images/how-it-works-delivery.png" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
                }
            }
            howItWorksTransfer: file(relativePath: { eq: "assets/images/how-it-works-transfer.png" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
                }
            }
            howItWorksNotifications: file(relativePath: { eq: "assets/images/how-it-works-notifications.png" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
                }
            }
            blueSkyBackground: file(relativePath: { eq: "assets/images/blue-sky.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
                }
            }
            birdHands: file(relativePath: { eq: "assets/images/bird-hands.png" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
                }
            }
            featureHighlightWoman: file(relativePath: { eq: "assets/images/feature-highlight-woman.png" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
                }
            }
            featureHighlightMan: file(relativePath: { eq: "assets/images/feature-highlight-man.png" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
                }
            }
            bpillwoman: file(relativePath: { eq: "assets/images/smiling-blond-woman-b-pill.png" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
                }
            }
        }
    `);

    return (
        <PageLayout>
            <PageSection>
                <Hero
                    sectionIndex={0}
                    backgroundColor="cerulean"
                    image={imageData.pill}
                    eyebrowText="Without feather ado"
                    imagePosition="bottom-right-breakout"
                    isTopOfFold={true}
                    linkTo="/get-started"
                    linkLabel="Get Started"
                    size="large"
                    title="Say hello to Birdi, your fast and friendly digital drug store."
                    text="With low drug prices, free and flexible delivery options, and expert help when you need it – Birdi makes it all a breeze."
                />
            </PageSection>
            <PageSection>
                <HowItWorks
                    sectionIndex={1}
                    eyebrowText="Looking for easier prescriptions?"
                    title="You've landed in the right spot"
                    ctaTo="/get-started"
                    ctaText="Get Started"
                    blocks={[
                        {
                            image: imageData.howItWorksTransfer,
                            title: 'Easy Transfer',
                            text:
                                'Send us your prescription, or ask your doctor to, and we’ll tell you when we get it. Have insurance? We’ll handle that to.'
                        },
                        {
                            image: imageData.howItWorksDelivery,
                            title: 'Free Delivery',
                            text:
                                'Have it delivered to your door for free, or pick it up at your local pharmacy. You choose.'
                        },
                        {
                            image: imageData.howItWorksNotifications,
                            title: 'No Surprises',
                            text:
                                'We’ll tell you as soon as your prescription is shipped and delivered. Easy. Fast. Friendly. Birdi.'
                        }
                    ]}
                />
            </PageSection>
            <PageSection>
                <Hero
                    sectionIndex={2}
                    backgroundColor="smoke"
                    eyebrowText="Say hello to birdi"
                    image={imageData.feather}
                    imagePosition="top-right-breakout"
                    isTopOfFold={false}
                    linkTo="/get-started"
                    linkLabel="Get Started"
                    size="large"
                    title="A pharmacy of a different feather."
                    text="Our team of experts saw how to make the pharmacy experience better. So we did away with long lines, lowered drug prices, and made Rx transfers easy. That’s Birdi."
                />
            </PageSection>
            <PageSection>
                <FeatureHighlight
                    sectionIndex={3}
                    backgroundColor="light_blue"
                    eyebrowText="Come fly with us"
                    headerText="Your fast and friendly digital drug store."
                    image={imageData.featureHighlightWoman}
                    imagePosition="left"
                    text="We’re a team of clinicians and experts in pharmacy, home delivery, and technology working together to make your life easier and healthier. Just send us your prescription, or ask your prescriber to, and see what we mean."
                    highlights={[
                        'Once your prescription is ready, you choose your payment and delivery option—either free standard delivery or rush options.',
                        'We’ll tell you as soon as your prescription is shipped, and delivered. Easy.',
                        'We’re hatching new ways to simplify the pharmacy experience, so check back soon to take advantage of our latest features.'
                    ]}
                />
            </PageSection>
            <PageSection>
                <FeatureHighlight
                    sectionIndex={4}
                    eyebrowText="The Pharmacy Nest Door"
                    headerText="An answer to soaring prices."
                    image={imageData.featureHighlightMan}
                    imagePosition="right"
                    text="If you are tired of paying too much for prescriptions, you’ve landed on the right spot. We leverage the purchasing power of millions of consumers to negotiate lower prices and pass the savings on to you."
                    highlights={[
                        'We work with your insurance company, so if your medications are covered by your plan, all you will have to pay is your deductible or copay.',
                        'Very soon, we’ll be launching new ways to help you compare prices and save money on all of your medications.'
                    ]}
                />
            </PageSection>
            <PageSection>
                <Quote
                    sectionIndex={5}
                    quoteIcon={<QuoteIcon />}
                    quote="Birdi is beautiful. Managing my prescriptions has never been easier."
                    speaker="Sally L."
                />
            </PageSection>
            <PageSection>
                <IconGrid
                    sectionIndex={6}
                    eyebrowText="03"
                    title="We help you take control of your health."
                    subtitle="Once you receive your medications, our work is just beginning. We're more than just another pharmacy, we'll be your partner in better health."
                    cta={{
                        linkText: 'Get started',
                        linkUri: '/get-started'
                    }}
                    image={imageData.bpillwoman}
                >
                    <GridIcon
                        icon={<QuoteIcon />}
                        subtitle="Got a lot on your mind? We're here for you."
                        bodyText="We'll let you know when it's time for a refill."
                    />
                    <GridIcon
                        icon={<QuoteIcon />}
                        subtitle="On your schedule."
                        bodyText="We have free standard delivery or rush options."
                    />
                    <GridIcon
                        icon={<QuoteIcon />}
                        subtitle="Questions answered."
                        bodyText="Our experts are here to answer your questions about medications, orders, and insurance coverage."
                    />
                    <GridIcon
                        icon={<QuoteIcon />}
                        subtitle="Because we care."
                        bodyText="We keep track of your medications and health conditions so we can alert you about potential interactions or other concerns."
                    />
                </IconGrid>
            </PageSection>
            <PageSection>
                <FrequentlyAskedQuestions
                    sectionIndex={7}
                    background="smoke"
                    allQuestions={[
                        {
                            question:
                                'Lorem ipsum dolor sin sit amet consectutor adipi dolor sin sit amet consectutor adipisicing?',
                            answer:
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum dolor sit amet, consectetur adipiscing elit. Dolor sin sit amet consectutor adipisicing quin qin. Lorem ipsum dolor sin sit amet consectutotr adipidicing. Lorem ipsum dolor sin sit amet consectutor adipisicng.',
                            id: 1
                        },
                        {
                            question:
                                'Lorem ipsum dolor sin sit amet consectutor adipi dolor sin sit amet consectutor adipisicing?',
                            answer:
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum dolor sit amet, consectetur adipiscing elit. Dolor sin sit amet consectutor adipisicing quin qin. Lorem ipsum dolor sin sit amet consectutotr adipidicing. Lorem ipsum dolor sin sit amet consectutor adipisicng.',
                            id: 2
                        },
                        {
                            question:
                                'Lorem ipsum dolor sin sit amet consectutor adipi dolor sin sit amet consectutor adipisicing?',
                            answer:
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum dolor sit amet, consectetur adipiscing elit. Dolor sin sit amet consectutor adipisicing quin qin. Lorem ipsum dolor sin sit amet consectutotr adipidicing. Lorem ipsum dolor sin sit amet consectutor adipisicng.',
                            id: 3
                        },
                        {
                            question:
                                'Lorem ipsum dolor sin sit amet consectutor adipi dolor sin sit amet consectutor adipisicing?',
                            answer:
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum dolor sit amet, consectetur adipiscing elit. Dolor sin sit amet consectutor adipisicing quin qin. Lorem ipsum dolor sin sit amet consectutotr adipidicing. Lorem ipsum dolor sin sit amet consectutor adipisicng.',
                            id: 4
                        },
                        {
                            question:
                                'Lorem ipsum dolor sin sit amet consectutor adipi dolor sin sit amet consectutor adipisicing?',
                            answer:
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum dolor sit amet, consectetur adipiscing elit. Dolor sin sit amet consectutor adipisicing quin qin. Lorem ipsum dolor sin sit amet consectutotr adipidicing. Lorem ipsum dolor sin sit amet consectutor adipisicng.',
                            id: 5
                        },
                        {
                            question:
                                'Lorem ipsum dolor sin sit amet consectutor adipi dolor sin sit amet consectutor adipisicing?',
                            answer:
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum dolor sit amet, consectetur adipiscing elit. Dolor sin sit amet consectutor adipisicing quin qin. Lorem ipsum dolor sin sit amet consectutotr adipidicing. Lorem ipsum dolor sin sit amet consectutor adipisicng.',
                            id: 6
                        },
                        {
                            question:
                                'Lorem ipsum dolor sin sit amet consectutor adipi dolor sin sit amet consectutor adipisicing?',
                            answer:
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum dolor sit amet, consectetur adipiscing elit. Dolor sin sit amet consectutor adipisicing quin qin. Lorem ipsum dolor sin sit amet consectutotr adipidicing. Lorem ipsum dolor sin sit amet consectutor adipisicng.',
                            id: 7
                        }
                    ]}
                />
            </PageSection>
            <PageSection>
                <HeroWithBackgroundImage
                    sectionIndex={8}
                    backgroundImage={imageData.blueSkyBackground}
                    image={imageData.birdHands}
                    imagePosition="bottom-right"
                    eyebrowText="Without feather ado"
                    isTopOfFold={true}
                    linkTo="/get-started"
                    linkLabel="Get Started"
                    size="large"
                    title="Getting your prescriptions filled shouldn’t be a pill."
                    text="We’re Birdi and we’re here to make your life easier and healthier."
                    useRoundedCorners={true}
                />
            </PageSection>
        </PageLayout>
    );
};

export default Home;
